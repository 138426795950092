<template>
  <div>
    <lz-list
      selection
      :config="config"
      :table="table"
      :search="search"
      :search-btn="searchBtn"
      ref="lzList"
      table-height="97%"
      @getdata="handleGetData"
      @upload-success="handleUploadSuccess"
      @search-btn-click="handleCommonBtnCLick"
      @table-checkbox-change="handleCheckboxChange"
      @table-btn-click="handleTableBtnClick">
      <div v-if="$route.name == 'code-recharge'" slot="pagePosition" style="font-size: 13px;" data-flex="">
        <div>总金额:<span class="ml-10 color-primary">{{ total_amount }}</span> 元</div>
        <div class="ml-10">余额:<span class="ml-10 color-primary">{{ balance }}</span> 元</div>
        <div class="ml-10">补付金额:<span class="ml-10 color-primary">{{ recharge_amount }}</span> 元</div>
      </div>
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      title="兑换码详情"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
    </lz-dialog>
    <lz-dialog
      :dialogBtns="dialogImprtBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      title="选择参数信息"
      :width="500"
      ref="lzImportDialog">
      <lz-form :form="importForm" :col="1" ref="lzImportForm"></lz-form>
    </lz-dialog>
    <lz-dialog
      :dialogBtns="dialogAddBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      title="添加兑换码"
      :width="500"
      ref="lzAddDialog">
      <lz-form :form="addForm" :col="1" ref="lzAddForm"></lz-form>
    </lz-dialog>
    <lz-dialog
      :dialogBtns="dialogRemoveBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      title="选择转移类型"
      :width="500"
      ref="lzRemoveDialog">
      <lz-form :form="removeForm" :col="1" ref="lzRemoveForm"></lz-form>
    </lz-dialog>
    <lz-dialog
      :dialogBtns="[{ key: 'rechargeSave', name: '保存', type: 'primary' }]"
      @dialog-btn-click="handleCommonBtnCLick"
      title="充值信息"
      :width="500"
      ref="lzRechargeDialog">
      <lz-form :form="rechargeForm" :col="1" ref="lzRechargeForm"></lz-form>
    </lz-dialog>
    <lz-dialog
      title="操作记录"
      :is-close-btn="false"
      ref="lzHistoryDialog">
      <lz-table-list
        :table="$route.name == 'code-recharge' ? [
            { name: '类型', prop: 'type_name' },
            { name: '操作时间', prop: 'create_time' },
            { name: '金额', prop: 'amount' },
            { name: '充值类型', prop: 'use_type_name' },
            { name: '备注', prop: 'memo' },
            { name: '操作人', prop: 'nick_name' },
          ] : [
          { name: '类型', prop: 'type_name' },
          { name: '操作时间', prop: 'create_time' },
          { name: '备注', prop: 'memo' },
          { name: '操作人', prop: 'nick_name' },
        ]"
        :list="history">
      </lz-table-list>
    </lz-dialog>
    <distribution ref="distribution" @success="$refs.lzList.getData()"></distribution>
  </div>
</template>
<script>
import { getAccessToken } from '@/utils/auth';
import { getSearchValue } from '@/utils/search';
import config from '@/config';
import recharge from './mixins/recharge';
import distribution from './distribution';

// const useType = [
//   { label: '未定义', value: 0 },
//   { label: '销售', value: 1 },
//   { label: '赠送', value: 2 },
//   { label: '更换', value: 3 },
// ];

// const len = [
//   { label: '1', value: 1 },
//   { label: '2', value: 2 },
//   { label: '3', value: 3 },
//   { label: '4', value: 4 },
//   { label: '5', value: 5 },
//   { label: '6', value: 6 },
// ];

const status = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
];

const importStatus = [
  { label: '重复不导入', value: 0 },
  { label: '重复则更新', value: 2 },
];

export default {
  mixins: [recharge],
  components: {
    distribution,
  },
  data() {
    return {
      attachmentId: '',
      search: [
        { name: '兑换码', type: 'text', fieldName: 'keyword', value: '' },
        {
          fieldName: 'sale_type',
          type: 'select',
          name: '销售渠道',
          value: '',
          options: [],
          constantDict: 'saleType',
        },
        {
          name: '兑换码类型',
          type: 'select',
          fieldName: 'type_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/ticket-type/list',
            value: 'id',
            label: 'name',
          },
          disabledValue: ['9'],
        },
        {
          fieldName: 'ticket_line_id',
          type: 'select',
          name: '产品线',
          value: '',
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-line/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: '分配', type: 'select', fieldName: 'is_assign', value: '', options: status },
        {
          fieldName: 'use_type',
          name: '出库类型',
          type: 'select',
          value: '',
          options: [],
          remote: true,
          props: {
            url: '/ticket-use-type/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'carrier_type',
          type: 'select',
          name: '载体类型',
          value: '',
          options: [],
          constantDict: 'carriertype',
        },
        {
          name: '分销商',
          type: 'select',
          fieldName: 'agent_id',
          value: '',
          options: [],
          remote: true,
          props: {
            url: '/agent/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '公司名称',
          type: 'select',
          fieldName: 'company_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/company/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: '订单id', type: 'text', fieldName: 'ticket_id', value: '' },
        { name: '年份', type: 'date', fieldName: 'year', value: '', format: 'yyyy', dateType: 'year' },
        {
          name: '应用场景',
          type: 'select',
          fieldName: 'scene_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/scene/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '用途',
          type: 'select',
          fieldName: 'purpose_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/purpose/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '档位',
          type: 'select',
          fieldName: 'price_title_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/price-title/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: '兑换', type: 'select', fieldName: 'is_exchange', value: '', options: status },
        { name: '作废', type: 'select', fieldName: 'is_cancel', value: '', options: status },
        { name: '退换', type: 'select', fieldName: 'is_return', value: '', options: status },
        { name: '有效期', type: 'daterange', fieldName: 'begin_time||end_time', value: [], width: '300px' },
        { name: '分配', type: 'daterange', fieldName: 'add_begin_time||add_end_time', value: [], width: '300px' },
        { name: '兑换', type: 'daterange', fieldName: 'exchange_begin_time||exchange_end_time', value: [], width: '300px' },
        // { name: '数量最小值', type: 'select', fieldName: 'exchange_num_min', value: '', options: len },
        // { name: '数量最大值', type: 'select', fieldName: 'exchange_num_max', value: '', options: len },
        // { name: '订单id', type: 'text', fieldName: 'ticket_id', value: '' },
        // {
        //   name: '操作人',
        //   type: 'select',
        //   fieldName: 'create_id',
        //   value: [],
        //   options: [],
        //   remote: true,
        //   props: {
        //     url: '/admin/list',
        //     value: 'id',
        //     label: 'nick_name',
        //   },
        // },
      ],
      searchBtn: [
        // { name: '批量删除', type: 'danger', key: 'deletes' },
      ],
      config: {
        url: '/code/list',
        setTableFormat: (list) => {
          list.forEach((el) => {
            if (el.btn_move) {
              el.htmlButton.push({
                name: '转移',
                key: 'move',
                type: 'success',
              });
            }
            if (el.btn_assign) {
              el.htmlButton.push({
                name: '撤销分配',
                key: 'revoke',
                type: 'warning',
              });
            }
            if (this.$route.meta.roleBtn.recharge
              && !el.is_del
              && this.$route.name === 'code-recharge') {
              el.htmlButton.push({ name: '充值', key: 'recharge', type: 'success' });
            }
            // 储值订单存在删除不允许转移
            if (this.$route.name === 'code-recharge' && el.is_del) {
              const btnMoveIndex = el.htmlButton.findIndex((r) => r.key === 'move');
              if (btnMoveIndex >= 0) el.htmlButton.splice(btnMoveIndex, 1);
            }
            if (el.is_exchange) {
              el.htmlButton.push({ name: '查看订单', key: 'order', type: 'success' });
            }
            el.htmlButton.push({ name: '操作记录', key: 'history', type: 'success' });
          });
          return list;
        },
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '兑换码', prop: 'code', width: '130px' },
        { name: '销售渠道', prop: 'sale_type_name', width: '130px' },
        { name: '兑换码类型', prop: 'type_name', width: '130px' },
        { name: '产品线', prop: 'ticket_line_name', width: '130px' },
        { name: '载体类型', prop: 'carrier_type_name', width: '130px' },
        { name: '出库类型', prop: 'use_type_text' },
        { name: '兑换数量', prop: 'exchange_num', width: '80px' },
        {
          name: '是否兑换',
          func: (ops) => (ops.is_exchange ? '是' : '否'),
          width: '100px',
        },
        {
          name: '是否作废',
          prop: 'is_cancel',
          width: '100px',
          type: 'switch',
          switchDisabled: (ops) => !(ops.btn_cancel),
          switchChange: (row) => {
            this.$basePrompt({
              content: '作废',
              callConfirm: async (memo) => {
                this.$baseHttp.post('/code/save', {
                  is_cancel: row.is_cancel,
                  id: row.id,
                  memo,
                }).then((response) => {
                  if (!response) {
                    this.$set(row, 'is_cancel', Number(!row.is_cancel));
                    return;
                  }
                  this.$message.success('操作成功');
                });
              },
              callCancel: () => this.$set(row, 'is_cancel', Number(!row.is_cancel)),
            });
          },
        },
        {
          name: '是否退换',
          prop: 'is_return',
          type: 'switch',
          width: '100px',
          switchDisabled: (ops) => !(ops.btn_return),
          switchChange: (row) => {
            this.$basePrompt({
              content: '退换',
              callConfirm: async (memo) => {
                this.$baseHttp.post('/code/save', {
                  is_return: row.is_return,
                  id: row.id,
                  memo,
                }).then((response) => {
                  if (!response) {
                    this.$set(row, 'is_return', Number(!row.is_return));
                    return;
                  }
                  this.$message.success('操作成功');
                });
              },
              callCancel: () => this.$set(row, 'is_return', Number(!row.is_return)),
            });
          },
        },
        { name: '公司名称', prop: 'company_name', width: '200px' },
        { name: '年份', prop: 'year' },
        { name: '场景', prop: 'scene_name' },
        { name: '用途', prop: 'purpose_name' },
        { name: '档位', prop: 'price_title_name' },
        {
          name: '有效期',
          func: (ops) => `${ops.begin_time}-${ops.end_time}`,
          width: '210px',
        },
        { name: '分配时间', prop: 'add_time', width: '180px' },
        {
          name: '兑换时间',
          prop: 'exchange_time',
          func: (ops) => (ops.exchange_time || '无'),
          width: '180px',
        },
        // { name: '导入类型', prop: 'import_type_text', width: '180px' },
        // { name: '联系人', prop: 'person_name' },
        // { name: '联系电话', prop: 'person_phone' },
        { name: '操作人', prop: 'nick_name', width: '180px' },
        { name: '操作', prop: 'htmlButton', fixed: 'right', width: '150px' },
      ],
      form: [
        { fieldName: 'code', type: 'text', name: '兑换码', value: '', required: true },
        { fieldName: 'pwd', type: 'text', name: '兑换密码', value: '', required: true },
        { fieldName: 'phone', type: 'text', name: '绑定手机号', value: '', required: false, disabled: true },
        { fieldName: 'person_name', type: 'text', name: '联系人', value: '', required: false },
        { fieldName: 'person_phone', type: 'text', name: '联系电话', value: '', required: false },
        {
          fieldName: 'is_cancel',
          type: 'select',
          name: '是否作废',
          required: true,
          value: '',
          options: status,
        },
        {
          fieldName: 'is_return',
          type: 'select',
          name: '是否退货',
          required: true,
          value: '',
          options: status,
        },
        {
          fieldName: 'order_type',
          type: 'select',
          name: '是否人工兑换',
          required: true,
          value: '',
          options: [
            { label: '不限制', value: 0 },
            { label: '人工兑换', value: 1 },
          ],
        },
        {
          name: '兑换码类型',
          type: 'select',
          fieldName: 'type_id',
          value: [],
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-type/list',
            value: 'id',
            label: 'name',
          },
          disabledValue: ['9'],
        },
        {
          fieldName: 'sale_type',
          type: 'select',
          name: '销售渠道',
          value: '',
          options: [],
          constantDict: 'saleType',
        },
        {
          fieldName: 'carrier_type',
          type: 'select',
          name: '载体类型',
          value: '',
          options: [],
          constantDict: 'carriertype',
        },
        {
          fieldName: 'ticket_line_id',
          type: 'select',
          name: '产品线',
          value: '',
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-line/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'use_type',
          name: '出库类型',
          type: 'select',
          value: '',
          options: [],
          required: true,
          remote: true,
          props: {
            url: '/ticket-use-type/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: 'id', type: 'input', fieldName: 'id', value: '', hide: true },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      importForm: [
        {
          fieldName: 'use_type',
          name: '出库类型',
          type: 'select',
          value: '',
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-use-type/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'sale_type',
          type: 'select',
          name: '销售渠道',
          value: '',
          options: [],
          required: false,
          constantDict: 'saleType',
        },
        // { name: '出库类型', type: 'select', fieldName: 'use_type', value: '0', options: useType, required: true },
        { name: '重复筛查', type: 'select', fieldName: 'type', value: '', options: importStatus, required: true },
        {
          name: '兑换码类型',
          type: 'select',
          fieldName: 'type_id',
          value: [],
          options: [],
          remote: true,
          required: false,
          props: {
            url: '/ticket-type/list',
            value: 'id',
            label: 'name',
          },
          disabledValue: ['9'],
        },
        {
          fieldName: 'ticket_line_id',
          type: 'select',
          name: '产品线',
          value: '',
          options: [],
          remote: true,
          required: false,
          props: {
            url: '/ticket-line/list',
            value: 'id',
            label: 'name',
          },
        },
      ],
      dialogImprtBtns: [
        { key: 'uploadSave', name: '保存', type: 'primary' },
      ],
      addForm: [
        // { name: '出库类型', type: 'select', fieldName: 'use_type', value: '0', options: useType, required: true },
        {
          fieldName: 'use_type',
          name: '出库类型',
          type: 'select',
          value: '',
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-use-type/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'sale_type',
          type: 'select',
          name: '销售渠道',
          value: '',
          options: [],
          required: false,
          constantDict: 'saleType',
        },
        {
          name: '兑换码类型',
          type: 'select',
          fieldName: 'type_id',
          value: [],
          options: [],
          remote: true,
          required: false,
          props: {
            url: '/ticket-type/list',
            value: 'id',
            label: 'name',
          },
          disabledValue: ['9'],
        },
        {
          fieldName: 'ticket_line_id',
          type: 'select',
          name: '产品线',
          value: '',
          options: [],
          remote: true,
          required: false,
          props: {
            url: '/ticket-line/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: '重复筛查', type: 'select', fieldName: 'type', value: '', options: importStatus, required: true },
        { name: '账号密码', type: 'textarea', fieldName: 'code', value: '', required: true, placeholder: '格式为兑换码 密码 添加多个分行添加' },
      ],
      dialogAddBtns: [
        { key: 'addSave', name: '保存', type: 'primary' },
      ],
      dialogRemoveBtns: [
        { key: 'removeSave', name: '保存', type: 'primary' },
      ],
      removeForm: [
        { name: 'id', type: 'input', fieldName: 'ids', value: '', hide: true },
        {
          name: '兑换码类型',
          type: 'select',
          fieldName: 'type_id',
          value: [],
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-type/list',
            value: 'id',
            label: 'name',
          },
          disabledValue: ['9'],
        },
        { name: '备注', type: 'textarea', fieldName: 'memo', value: '', width: '100%', required: true },
      ],
      rechargeForm: [
        { name: 'id', type: 'input', fieldName: 'ids', value: '', hide: true },
        { name: '充值金额', type: 'number', fieldName: 'amount', value: '', width: '100%', required: true },
        {
          fieldName: 'use_type',
          name: '充值类型',
          type: 'select',
          value: '',
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-use-type/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: '备注', type: 'textarea', fieldName: 'memo', value: '', width: '100%', required: false },
      ],
      values: [],
      history: [],
      total_amount: '',
      balance: '',
      recharge_amount: '',
    };
  },
  created() {
    if (this.$route.name === 'code-recharge') this.initReCharge();
  },
  methods: {
    handleGetData(response) {
      this.$set(this, 'total_amount', response.total_amount);
      this.$set(this, 'balance', response.balance);
      this.$set(this, 'recharge_amount', response.recharge_amount);
    },
    async handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/code/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        case 'revoke':
          this.$basePrompt({
            content: '确定撤销分配',
            callConfirm: async (value) => {
              const response = await this.$baseHttp.post('/code/assign', {
                ids: element.id,
                memo: value,
              });
              if (!response) return;
              this.$message.success('操作成功');
              lzList.getData();
            },
          });
          break;
        case 'move':
          if (this.$route.name === 'code-recharge') {
            this.$message.warning('储值卡不支持转移');
            return;
          }
          this.$refs.lzRemoveDialog.dialogVisible = true;
          this.removeForm[0].value = element.id;
          break;
        case 'order':
          this.$router.push({
            name: 'order-list',
            query: {
              code: element.code,
            },
          });
          break;
        case 'history': {
          const response = await this.$baseHttp.get('/code-log/list', {
            params: { code_id: element.id },
          });
          this.history = response.data;
          this.$refs.lzHistoryDialog.dialogVisible = true;
        }
          break;
        case 'recharge':
          this.$refs.lzRechargeDialog.dialogVisible = true;
          this.rechargeForm[0].value = element.id;
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          delete params.phone;
          if (this.$route.name === 'code-recharge') params.type_id = 9;
          const response = await this.$baseHttp.post('/code/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'uploadSave': {
          const params = this.$refs.lzImportForm.getValue();
          if (!params) return;
          params.attachment_id = this.attachmentId;
          if (this.$route.name === 'code-recharge') params.type_id = 9;
          const response = await this.$baseHttp.post('/code/import', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzImportDialog.dialogVisible = false;
        }
          break;
        case 'addSave': {
          const params = this.$refs.lzAddForm.getValue();
          if (!params) return;
          params.type = 1;
          if (this.$route.name === 'code-recharge') params.type_id = 9;
          const response = await this.$baseHttp.post('/code/add', params);
          if (!response) return;
          this.$message.success(response.data);
          this.$refs.lzList.getData();
          this.$refs.lzAddDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.$refs.lzAddDialog.dialogVisible = true;
          this.$nextTick(() => {
            this.$refs.lzAddForm.setValue({
              data: {
                use_type: 5,
                type: this.$route.name === 'code-recharge' ? 0 : '',
              },
            });
          });
          break;
        case 'assign':
          if (this.values.length === 0) {
            this.$message.error('请选择数据');
            return;
          }
          this.$basePrompt({
            content: '确定撤销分配',
            callConfirm: async (memo) => {
              const ids = this.values.join(',');
              const response = await this.$baseHttp.post('/code/assign', {
                ids,
                memo,
              });
              if (!response) return;
              this.$message.success('操作成功');
              this.$refs.lzList.getData();
            },
          });
          break;
        case 'cancel':
        case 'return':
          if (this.values.length === 0) {
            this.$message.error('请选择数据');
            return;
          }
          this.$basePrompt({
            content: type === 'cancel' ? '确定作废?' : '确定退换?',
            callConfirm: async (memo) => {
              const ids = this.values.join(',');
              const response = await this.$baseHttp.post('/code/action', {
                type,
                value: 1,
                ids,
                memo,
              });
              if (!response) return;
              this.$message.success('操作成功');
              this.$refs.lzList.getData();
            },
          });
          break;
        case 'delete':
          if (this.values.length === 0) {
            this.$message.error('请选择数据');
            return;
          }
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const ids = this.values.join(',');
              const response = await this.$baseHttp.delete(`/code/delete?ids=${ids}`);
              if (!response) return;
              this.$message.success('删除成功');
              this.$refs.lzList.getData();
            },
          });
          break;
        case 'move':
          if (this.$route.name === 'code-recharge') {
            this.$message.warning('储值卡不支持转移');
            return;
          }
          if (this.values.length === 0) {
            this.$message.error('请选择数据');
            return;
          }
          this.$refs.lzRemoveDialog.dialogVisible = true;
          this.removeForm[0].value = this.values.join(',');
          break;
        case 'recharge':
          if (this.$route.name !== 'code-recharge') {
            this.$message.warning('当前页面不支持批量充值');
            return;
          }
          if (this.values.length === 0) {
            this.$message.error('请选择数据');
            return;
          }
          this.$refs.lzRechargeDialog.dialogVisible = true;
          this.rechargeForm[0].value = this.values.join(',');
          break;
        case 'set-assign': {
          if (this.values.length === 0) {
            this.$message.error('请选择数据');
            return;
          }
          // 过滤已兑换且未分配且未作废
          const list = this.$refs.lzList.list.filter((r) => this.values.includes(r.id) && r.is_exchange === 0 && r.btn_assign !== 1 && r.is_cancel !== 1);
          if (list.length === 0) {
            this.$message.error('无可分配的数据请核对');
            return;
          }
          this.$refs.distribution.init(list);
        }
          break;
        case 'removeSave': {
          const params = this.$refs.lzRemoveForm.getValue();
          if (!params) return;
          const response = await this.$baseHttp.post('/code/move', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzRemoveDialog.dialogVisible = false;
        }
          break;
        case 'rechargeSave': {
          const params = this.$refs.lzRechargeForm.getValue();
          if (!params) return;
          const response = await this.$baseHttp.post('/code/recharge', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzRechargeDialog.dialogVisible = false;
        }
          break;
        case 'export': {
          const query = getSearchValue(this.search);
          query.is_export = 1;
          query.token = getAccessToken();
          delete query.page;
          delete query.page_size;
          if (this.$route.name === 'code-recharge') query.type_id = 9;
          window.open(`${config.api}/code/list?${this.$jsonToUrl(query)}`);
        }
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.form.filter((r) => ['exchange_num', 'begin_time||end_time', 'memo'].includes(r.fieldName)).forEach((el) => {
        const index = this.form.findIndex((r) => r.fieldName === el.fieldName);
        if (index >= 0) this.form.splice(index, 1);
      });
      if (response.ticket_id !== 0) {
        this.form.push(
          { fieldName: 'exchange_num', type: 'number', name: '兑换数量', value: '', required: true },
          { fieldName: 'begin_time||end_time', type: 'daterange', name: '兑换时间', value: [response.begin_time, response.end_time], required: true, width: '66.66%' },
        );
      }
      this.form.push({ name: '备注', type: 'textarea', fieldName: 'memo', value: '', width: '100%' });
      const code = this.form.find((r) => r.fieldName === 'code');
      if (code) this.$set(code, 'disabled', !!response.is_exchange);
      const pwd = this.form.find((r) => r.fieldName === 'pwd');
      if (pwd) this.$set(pwd, 'disabled', !!response);
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response || {},
        });
      });
    },
    async handleUploadSuccess(response) {
      this.attachmentId = response.data[0].id;
      if (response.type === 'import_person') { // 批量导入联系人/联系方式
        const response = await this.$baseHttp.post('/code/import-person', {
          attachment_id: this.attachmentId,
        });
        if (!response) return;
        this.$message.success('操作成功');
        this.$refs.lzList.getData();
        return;
      }
      this.$refs.lzImportDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzImportForm.setValue({
          data: { use_type: 5 },
        });
      });
    },
    handleCheckboxChange(values) {
      this.values = values.map((r) => r.id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
