const status = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
];

const importStatus = [
  { label: '重复不导入', value: 0 },
  { label: '重复则更新', value: 2 },
];

export default {
  methods: {
    initReCharge() {
      this.config.url = '/code/list?type_id=9';
      this.setRechargeSearch();
      this.setRechargeTable();
      this.setRechargeForm();
      this.setRechargeAddForm();
    },
    setRechargeTable() {
      this.table = [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '账号', prop: 'code', width: '130px' },
        { name: '销售渠道', prop: 'sale_type_name', width: '130px' },
        { name: '客户名称', prop: 'company_name', width: '200px' },
        { name: '产品线', prop: 'ticket_line_name', width: '130px' },
        { name: '载体类型', prop: 'carrier_type_name', width: '130px' },
        { name: '总金额', prop: 'total_amount', width: '130px' },
        {
          name: '是否兑换',
          func: (ops) => (ops.is_exchange ? '是' : '否'),
          width: '100px',
        },
        { name: '兑换数量', prop: 'exchange_num', width: '80px' },
        { name: '余额', prop: 'balance', width: '130px' },
        { name: '补付金额', prop: 'recharge_amount', width: '130px' },
        {
          name: '是否作废',
          prop: 'is_cancel',
          width: '100px',
          type: 'switch',
          switchDisabled: (ops) => !(ops.btn_cancel),
          switchChange: (row) => {
            this.$basePrompt({
              content: '作废',
              callConfirm: async (memo) => {
                this.$baseHttp.post('/code/save', {
                  is_cancel: row.is_cancel,
                  id: row.id,
                  memo,
                }).then((response) => {
                  if (!response) {
                    this.$set(row, 'is_cancel', Number(!row.is_cancel));
                    return;
                  }
                  this.$message.success('操作成功');
                });
              },
              callCancel: () => this.$set(row, 'is_cancel', Number(!row.is_cancel)),
            });
          },
        },
        {
          name: '是否退换',
          prop: 'is_return',
          type: 'switch',
          width: '100px',
          switchDisabled: (ops) => !(ops.btn_return),
          switchChange: (row) => {
            this.$basePrompt({
              content: '退换',
              callConfirm: async (memo) => {
                this.$baseHttp.post('/code/save', {
                  is_return: row.is_return,
                  id: row.id,
                  memo,
                }).then((response) => {
                  if (!response) {
                    this.$set(row, 'is_return', Number(!row.is_return));
                    return;
                  }
                  this.$message.success('操作成功');
                });
              },
              callCancel: () => this.$set(row, 'is_return', Number(!row.is_return)),
            });
          },
        },
        {
          name: '有效期',
          func: (ops) => `${ops.begin_time}-${ops.end_time}`,
          width: '210px',
        },
        { name: '分配时间', prop: 'add_time', width: '180px' },
        { name: '操作', prop: 'htmlButton', fixed: 'right', width: '150px' },
      ];
    },
    setRechargeSearch() {
      this.search = [
        { name: '账号', type: 'text', fieldName: 'keyword', value: '' },
        {
          name: '客户名称',
          type: 'select',
          fieldName: 'company_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/company/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'sale_type',
          type: 'select',
          name: '销售渠道',
          value: '',
          options: [],
          constantDict: 'saleType',
        },
        {
          name: '分销商',
          type: 'select',
          fieldName: 'agent_id',
          value: [],
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/agent/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: '订单id', type: 'text', fieldName: 'ticket_id', value: '' },
        {
          fieldName: 'ticket_line_id',
          type: 'select',
          name: '产品线',
          value: '',
          options: [],
          remote: true,
          width: '150px',
          props: {
            url: '/ticket-line/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: '年份', type: 'date', fieldName: 'year', value: '', format: 'yyyy', dateType: 'year' },
        {
          name: '场景',
          type: 'select',
          fieldName: 'scene_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/scene/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '用途',
          type: 'select',
          fieldName: 'purpose_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/purpose/list',
            value: 'id',
            label: 'name',
          },
        },
        { name: '兑换', type: 'select', fieldName: 'is_exchange', value: '', options: status },
        { name: '作废', type: 'select', fieldName: 'is_cancel', value: '', options: status },
        { name: '退换', type: 'select', fieldName: 'is_return', value: '', options: status },
        { name: '余额最小值', type: 'text', fieldName: 'balance_min', value: '' },
        { name: '余额最大值', type: 'text', fieldName: 'balance_max', value: '' },
        { name: '分配', type: 'select', fieldName: 'is_assign', value: '', options: status },
      ];
    },
    setRechargeForm() {
      this.form = [
        { fieldName: 'code', type: 'text', name: '账户', value: '', required: true },
        { fieldName: 'pwd', type: 'text', name: '密码', value: '', required: true },
        { fieldName: 'phone', type: 'text', name: '绑定手机号', value: '', required: false, disabled: true },
        { fieldName: 'person_name', type: 'text', name: '联系人', value: '', required: false },
        { fieldName: 'person_phone', type: 'text', name: '联系电话', value: '', required: false },
        {
          fieldName: 'is_cancel',
          type: 'select',
          name: '是否作废',
          required: true,
          value: '',
          options: status,
        },
        {
          fieldName: 'is_return',
          type: 'select',
          name: '是否退货',
          required: true,
          value: '',
          options: status,
        },
        // {
        //   fieldName: 'order_type',
        //   type: 'select',
        //   name: '是否人工兑换',
        //   required: true,
        //   value: '',
        //   options: [
        //     { label: '不限制', value: 0 },
        //     { label: '人工兑换', value: 1 },
        //   ],
        // },
        // {
        //   name: '兑换码类型',
        //   type: 'select',
        //   fieldName: 'type_id',
        //   value: [],
        //   options: [],
        //   remote: true,
        //   required: true,
        //   props: {
        //     url: '/ticket-type/list',
        //     value: 'id',
        //     label: 'name',
        //   },
        // },
        {
          fieldName: 'sale_type',
          type: 'select',
          name: '销售渠道',
          value: '',
          options: [],
          constantDict: 'saleType',
        },
        {
          fieldName: 'carrier_type',
          type: 'select',
          name: '载体类型',
          value: '',
          options: [],
          constantDict: 'carriertype',
        },
        {
          fieldName: 'ticket_line_id',
          type: 'select',
          name: '产品线',
          value: '',
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-line/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'use_type',
          name: '出库类型',
          type: 'select',
          value: '',
          options: [],
          required: true,
          remote: true,
          props: {
            url: '/ticket-use-type/list',
            value: 'id',
            label: 'name',
          },
        },
        { fieldName: 'agent_name', type: 'text', name: '分销商', value: '', disabled: true },
        { fieldName: 'year', type: 'text', name: '年份', value: '', disabled: true },
        { fieldName: 'scene_name', type: 'text', name: '场景', value: '', disabled: true },
        { fieldName: 'purpose_name', type: 'text', name: '用途', value: '', disabled: true },
        { fieldName: 'balance', type: 'text', name: '余额', value: '', disabled: true },
        { fieldName: 'total_amount', type: 'text', name: '总金额', value: '', disabled: true },
        // {
        //   fieldName: 'total_amount',
        //   type: 'number',
        //   name: '总金额',
        //   value: '',
        //   required: true,
        // },
        // {
        //   fieldName: 'balance',
        //   type: 'number',
        //   name: '余额',
        //   value: '',
        //   required: true,
        // },
        // {
        //   fieldName: 'recharge_amount',
        //   type: 'number',
        //   name: '补付金额',
        //   value: '',
        //   required: true,
        // },
        { name: 'id', type: 'input', fieldName: 'id', value: '', hide: true },
      ];
    },
    setRechargeAddForm() {
      this.addForm = [
        {
          name: '分销商',
          type: 'select',
          fieldName: 'agent_id',
          value: [],
          options: [],
          remote: true,
          required: false,
          props: {
            url: '/agent/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'sale_type',
          type: 'select',
          name: '销售渠道',
          value: '',
          options: [],
          required: false,
          constantDict: 'saleType',
        },
        {
          fieldName: 'ticket_line_id',
          type: 'select',
          name: '产品线',
          value: '',
          options: [],
          remote: true,
          required: false,
          props: {
            url: '/ticket-line/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'use_type',
          name: '充值类型',
          type: 'select',
          value: '',
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-use-type/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'amount',
          type: 'number',
          name: '充值金额',
          value: '',
          required: true,
        },
        { name: '重复筛查', type: 'select', fieldName: 'type', value: '', options: importStatus, required: true },
        { name: '账号密码', type: 'textarea', fieldName: 'code', value: '', required: true, placeholder: '格式为兑换码 密码 添加多个分行添加' },
      ];
      this.importForm = [
        {
          name: '分销商',
          type: 'select',
          fieldName: 'agent_id',
          value: [],
          options: [],
          remote: true,
          required: false,
          props: {
            url: '/agent/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'sale_type',
          type: 'select',
          name: '销售渠道',
          value: '',
          options: [],
          required: false,
          constantDict: 'saleType',
        },
        {
          fieldName: 'ticket_line_id',
          type: 'select',
          name: '产品线',
          value: '',
          options: [],
          remote: true,
          required: false,
          props: {
            url: '/ticket-line/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'use_type',
          name: '充值类型',
          type: 'select',
          value: '',
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-use-type/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          fieldName: 'amount',
          type: 'number',
          name: '充值金额',
          value: '',
          required: true,
        },
        { name: '重复筛查', type: 'select', fieldName: 'type', value: '', options: importStatus, required: true },
      ];
    },
  },
};
