<template>
  <lz-dialog
    :dialogBtns="dialogBtns"
    @dialog-btn-click="handleCommonBtnCLick"
    title="兑换码分配"
    :width="1200"
    ref="lzDialog">
    <lz-card title="可分配的兑换码">
      <lz-table-list
        :table="table"
        @table-btn-click="handleCommonBtnCLick"
        :list="list">
      </lz-table-list>
    </lz-card>
    <lz-form :form="form" :col="1" ref="lzForm"></lz-form>
  </lz-dialog>
</template>
<script>
import dayjs from 'dayjs';

export default {
  data() {
    return {
      dialogBtns: [{ key: 'submit', name: '提交', type: 'primary' }],
      form: [],
      list: [],
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '兑换码', prop: 'code' },
        { name: '兑换数量', prop: 'exchange_num' },
        {
          name: '可兑换时间',
          func: (ops) => `${ops.begin_time}-${ops.end_time}`,
        },
        { name: '操作', prop: 'htmlButton', fixed: 'right', width: '150px' },
      ],
    };
  },
  mounted() {
    if (this.$route.name === 'code-recharge') {
      this.form = [
        {
          name: '订单',
          type: 'select',
          fieldName: 'ticket_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/ticket/list?type_id=9',
            value: 'id',
            label: 'id||company_name||year||scene_name||sale_type_name||type_name||ticket_line_name',
          },
          required: true,
        },
        {
          fieldName: 'carrier_type',
          type: 'select',
          name: '载体类型',
          value: '',
          options: [],
          constantDict: 'carriertype',
          required: true,
        },
        { name: '充值金额', type: 'number', fieldName: 'amount', value: '', width: '100%', required: true },
        {
          fieldName: 'use_type',
          name: '充值类型',
          type: 'select',
          value: '',
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-use-type/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '兑换时间',
          type: 'daterange',
          fieldName: 'begin_time||end_time',
          value: [],
          required: false,
        },
        { name: '备注', type: 'textarea', fieldName: 'memo', value: '', width: '100%', required: false },
      ];
    } else {
      this.form = [
        {
          name: '订单',
          type: 'select',
          fieldName: 'ticket_id',
          value: [],
          options: [],
          remote: true,
          props: {
            url: '/ticket/list',
            value: 'id',
            label: 'id||company_name||year||scene_name||sale_type_name||type_name||ticket_line_name',
          },
          required: true,
        },
        {
          fieldName: 'carrier_type',
          type: 'select',
          name: '载体类型',
          value: '',
          options: [],
          constantDict: 'carriertype',
          required: true,
        },
        {
          fieldName: 'use_type',
          name: '出库类型',
          type: 'select',
          value: '',
          options: [],
          remote: true,
          required: true,
          props: {
            url: '/ticket-use-type/list',
            value: 'id',
            label: 'name',
          },
        },
        {
          name: '兑换时间',
          type: 'daterange',
          fieldName: 'begin_time||end_time',
          value: [],
          required: false,
        },
        { name: '备注', type: 'textarea', fieldName: 'memo', value: '', width: '100%', required: true },
      ];
    }
  },
  methods: {
    init(values) {
      this.list = values.map((r) => ({
        ...r,
        htmlButton: [{ name: '删除', key: 'del', type: 'danger' }],
      }));
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: {
            use_type: 5,
            begin_time: dayjs().format('YYYY-MM-DD'),
            end_time: dayjs().add(3, 'year').format('YYYY-MM-DD'),
          },
        });
      });
    },
    async handleCommonBtnCLick(type, element) {
      switch (type) {
        case 'del': {
          const index = this.list.find((r) => r.id === element.id);
          this.list.splice(index, 1);
        }
          break;
        case 'submit': {
          if (this.list.length === 0) {
            this.$message.error('无兑换码可分配');
            return;
          }
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          params.ids = this.list.map((r) => r.id).toString();
          const response = await this.$baseHttp.post('/code/set-assign', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$emit('success');
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
